import React, { useEffect } from 'react'
import { Layout } from "../../components/layout"
import { Offerings } from '../../components/offering-section'
import { Section } from '../../components/section'
import { useLanguage, passLanguage } from '../../components/language-context'

import { Row, RowText, Spacer, RowLeft, RowRight, smoothScrollTo } from '../../components/offering-common-components';

import deliver1DesktopImage from '../../images/offering/deliver/desktop/deliver-1@2x.png'
import deliver2DesktopImage from '../../images/offering/deliver/desktop/deliver-2@2x.png'
import deliver3DesktopImage from '../../images/offering/deliver/desktop/deliver-3@2x.png'
import deliver4DesktopImage from '../../images/offering/deliver/desktop/deliver-4@2x.png'
import deliver5DesktopImage from '../../images/offering/deliver/desktop/deliver-5@2x.png'
import deliver6DesktopImage from '../../images/offering/deliver/desktop/deliver-6@2x.png'

import deliver1MobileImage from '../../images/offering/deliver/mobile/deliver-1.png'
import deliver2MobileImage from '../../images/offering/deliver/mobile/deliver-2.png'
import deliver3MobileImage from '../../images/offering/deliver/mobile/deliver-3.png'
import deliver4MobileImage from '../../images/offering/deliver/mobile/deliver-4.png'
import deliver5MobileImage from '../../images/offering/deliver/mobile/deliver-5.png'
import deliver6MobileImage from '../../images/offering/deliver/mobile/deliver-6.png'

const imagesDesktop = [deliver1DesktopImage, deliver2DesktopImage, deliver3DesktopImage, deliver4DesktopImage, deliver5DesktopImage, deliver6DesktopImage];
const imagesMobile = [deliver1MobileImage, deliver2MobileImage, deliver3MobileImage, deliver4MobileImage, deliver5MobileImage, deliver6MobileImage];

const IndexPageBase = useLanguage(({location, msg, lang}) => {

  const paddingMainSection = {
    mobile: "0px 0px 10px 0px"
  }

  const sectionHeight = {
    mobile: '58px',
    desktop: '122px'
  }

  const marginFirstRow = {
    mobile: "15px 0 10px 0"
  }
  
  
  useEffect(() => {
    smoothScrollTo("#deliver-section");
  }, []);

  return (
    <Layout location={location}>
      <Section title={msg('home-offering')} lang={lang}>
        <Offerings page='deliver' />
      </Section>
      <Section title={msg('deliver-title')} showTitleLine={false} showLine={true} lineColor="#38b379" titleHeight={sectionHeight} lang={lang} id="deliver-section">
        <Row margin="0">
          <RowText>
          { msg('deliver-text') }
          </RowText>
        </Row>
      </Section>
      <Section padding={paddingMainSection}>
        <RowRight title={msg('deliver-1-title')} imageIndex={0} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile} margin={marginFirstRow}>
          { msg('deliver-1-text') }
        </RowRight>

        <RowLeft title={msg('deliver-2-title')} imageIndex={1} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('deliver-2-text') }
        </RowLeft>

        <RowRight title={msg('deliver-3-title')} imageIndex={2} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('deliver-3-text') }
        </RowRight>

        <RowLeft title={msg('deliver-4-title')} imageIndex={3} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('deliver-4-text') }
        </RowLeft>

        <RowRight title={msg('deliver-5-title')} imageIndex={4} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('deliver-5-text') }
        </RowRight>

        <RowLeft title={msg('deliver-6-title')} imageIndex={5} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('deliver-6-text') }
        </RowLeft>
      </Section>
      <Spacer />
    </Layout>
  )}
);

const IndexPage = passLanguage(IndexPageBase)

export default IndexPage
